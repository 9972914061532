.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 80%;
    margin: 0 8%;
}

.content img {
    height: 36px;
    width: 116px;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    height: 36px;
    width: 36px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.iconWrapper .icon {
    animation: slide 2s infinite ease-in-out;
}

.iconWrapper:hover .icon { 
    animation-play-state: paused;
}
