.progress {
    color: green;
    font-weight: bold;
}

.uploadBlock {
    display: flex;
    gap: 4px;
    align-items: baseline;
    font-size: var(--common-font-size-too-light);
}

.uploadIcon {
    border-radius: 50%;
    transition: background-color 0.2s;
    cursor: pointer;
}

.uploadIcon:hover {
    background-color: var(--common-color-gray);
}

.hiddenInput {
    display: none;
}