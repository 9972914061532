.content {
    width: 40%;
    margin: 16vh auto 0;
}

.subjectAndClose {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
}

.subject {
    font-size: 24px;
}

.block {
    background-color: var(--common-color-gray);
    padding: 16px;
    border-radius: 6px;
    box-shadow: var(--common-box-shadow);

    td {
        padding: 8px 16px;
        border-bottom: 1px solid #e1e1e1;
        vertical-align: middle; 
    }

    tr:last-child td {
        border-bottom: none;
    }
}

.date {
    color: var(--common-color-purple);
}

.display:hover {
    box-shadow: 0 var(--common-border-width-middle) 0 var(--common-color-purple);
}

.icon {
    border: none;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        background-color: var(--common-color-shadow);
    }
}

.closeButton {
    display: flex; 
    align-items: center; 
    justify-content: center;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    transition: background-color 0.2s;
}

.closeButton:hover {
    background-color: var(--common-color-gray);
}

@media (min-width: 600px) and (max-width: 899px) {
    .content {
        margin: 8vh auto 0;
        width: 80%;
    }
}
 
@media screen and (max-width: 599px) {
    .content {
        margin: 4vh auto 0;
        width: 100%;
    }

    .subjectAndClose {
       width: 90%;
       margin: 0 auto 12px;
    }

    .date {
        font-size: var(--common-font-size-light);
    }
}
 
@media (orientation: landscape) and (max-width: 1200px) {
    .content {
        margin: 10vh auto 0;
        width: 80%;
    }
}