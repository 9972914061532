.backgroundColor {
    background-color: rgba(0, 0, 0, 0.8);
}

.imageSize {
    width: 30%;
    height: auto;
}

.videoSize {
    width: 60%;
    height: auto;
}

@media (min-width: 600px) and (max-width: 899px) {
   .imageSize {
        width: 50%;
   }
   .videoSize {
        width: 70%;
   }
}

@media screen and (max-width: 599px) {
    .imageSize {
        width: 70%;
    }
    .videoSize {
        width: 80%;
   }
}

@media (orientation: landscape) and (max-width: 1200px) {
    .backgroundColor {
        background-color: #000;
    }

    .imageSize, .videoSize {
        width: 90%;
        height: 90%;
        object-fit: contain;
    }
}