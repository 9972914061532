.error {
    color: red;
    font-weight: bold;
}

.content {
    width: 40%;
    margin: 16vh auto 0;
}

.planContent {
    width: 72%;
    margin: 14vh auto 0;
}

.subjectAndClose {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
}

.subject {
    font-size: 24px;
}

.closeButton {
    display: flex; 
    align-items: center; 
    justify-content: center;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    transition: background-color 0.2s;
}

.closeButton:hover {
    background-color: var(--common-color-gray);
}

.block {
    display: flex;
    gap: 16px;
    align-items: center;
}

.current {
    color: var(--common-color-purple);
    font-weight: bold;
}

.currentValue {
    font-size: var(--common-font-size-heavy);
    font-weight: bold;
}

.changeBlock {
    margin-top: 4px;
    margin-left: 14px;

    span {
        color: blue;
        font-size: var(--common-font-size-too-light);
        cursor: pointer;
    }
}

.form {
    background-color: var(--common-color-gray);
    padding: 30px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: var(--common-box-shadow);

    input {
        border-radius: 6px;
        box-shadow: inset 0 0 0 var(--common-border-width-light) var(--common-color-shadow);
        line-height: 40px;
        padding: 0 12px;
        transition: all 0.2s; 

        &:focus {
            border-color: var(--common-color-purple);
            box-shadow: inset 0 0 0 var(--common-border-width-light) var(--common-color-purple);
        }
    }

    button {
        color: var(--common-color-white);
        font-size: var(--common-font-size-heavy);
        font-weight: bold;
        border-radius: 6px;
        background-color: #694358;
        width: max-content;
        padding: 8px 16px;
        transition: background-color 0.3s; 

        &:hover {
            background-color: var(--common-color-purple);
        }
    }
}

.invoiceTable {
    border-collapse: collapse;
    width: 100%;
    margin: 12px 0 24px;
  
    th, td {
        border: 1px solid;
        padding: 10px;
        vertical-align: middle;
        text-align: center;
    }

    th {
        background-color: var(--common-color-gray);
    }

    span {
        font-size: var(--common-font-size-too-light);
    }
}

.planTable {
    border-collapse: collapse;
    width: 100%;
    margin: 12px 0 24px;
  
    th, td {
        border: 1px solid;
        padding: 10px;
        vertical-align: middle;
        text-align: center;
    }

    th {
        background-color: var(--common-color-gray);
    }

    span {
        font-size: var(--common-font-size-too-light);
    }
}

.changePlan {
    margin-top: 24px;
}

.cartButton {
    color: var(--common-color-white);
    font-weight: bold;
    border-radius: 6px;
    background-color: #694358;
    width: max-content;
    padding: 6px 12px;
    transition: background-color 0.3s; 

    &:hover {
        background-color: var(--common-color-purple);
    }
}

.buyButton {
    font-size: var(--common-font-size-heavy);
    color: var(--common-color-white);
    font-weight: bold;
    border-radius: 6px;
    background-color: #694358;
    width: max-content;
    padding: 8px 16px;
    transition: background-color 0.3s; 

    &:hover {
        background-color: var(--common-color-purple);
    }
}

.productRow {
    display: flex;
    align-items: center;
    justify-content: space-around;
  
    .nameContainer {
        flex-grow: 1;
    }

    .thumbnailImage {
        max-width: 40px;
        max-height: 40px;
    };
}

.apply {
    margin: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

@media (min-width: 600px) and (max-width: 899px) {
    .content {
        margin: 8vh auto 0;
        width: 80%;
    }

    .planContent {
        margin: 6vh auto 0;
        width: 80%;
    }

    th.vertical {
        writing-mode: vertical-rl;
    }

    td.vertical {
        writing-mode: vertical-rl;
    }

    span {
        margin-top: 4px;
        display: block;
    }
}
 
@media screen and (max-width: 599px) {
    .content {
        margin: 4vh auto 0;
        width: 100%;
    }

    .subjectAndClose {
        width: 90%;
        margin: 0 auto 12px;
    }

    .planContent {
        margin: 4vh auto 0;
        width: 90%;
    }

    .planTable {
        th, td {
            font-size: var(--common-font-size-light);
        }

        th.vertical {
            writing-mode: vertical-rl;
        }
        
        span {
            margin-top: 4px;
            display: block;
        }
    }

    .invoiceTable {
        width: 100%;
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .productRow {
        display: flex;
        flex-direction: column;
    }
}
 
@media (orientation: landscape) and (max-width: 1200px) {
    .content {
        margin: 10vh auto 0;
        width: 80%;
    }

    .planContent {
        margin: 8vh auto 0;
        width: 80%;
    }

    td.vertical {
        writing-mode: vertical-rl;
    }
}