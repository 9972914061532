.tabWrapper {
    display: flex;
    gap: 16px;
}

.tabWrapper button {
    font-size: var(--common-font-size-heavy);
    border: solid var(--common-border-width-light) var(--common-color-shadow);
    border-radius: 6px;
    padding: 10px 20px;
    transition: background-color 0.3s;
    background-color: transparent; 
    cursor: pointer;

    &:hover {
        background-color: var(--common-color-none-active-hover);
    }

    &.active {
        background-color: var(--common-color-purple);
        color: var(--common-color-white);

        &:hover {
            background-color: var(--common-color-purple);
        }
    }
}

@media screen and (max-width: 599px) {
    .tabWrapper {
        gap: 12px;
    }

    .tabWrapper button {
        font-size: var(--common-font-size-middle);
    }
}
