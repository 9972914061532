.tab {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 16px 0;
}

.tab button {
    font-weight: bold;
    font-size: var(--common-font-size-heavy);
    border: solid var(--common-border-width-light) var(--common-color-shadow);
    border-radius: 4px;
    padding: 8px 16px;
    transition: background-color 0.3s;
    background-color: transparent; 
    cursor: pointer;

    &:hover {
        background-color: var(--common-color-none-active-hover);
    }

    &.active {
        background-color: var(--common-color-purple);
        color: var(--common-color-white);

        &:hover {
            background-color: var(--common-color-purple);
        }
    }
}