:root {
    --common-border-width-light: 1px;
    --common-border-width-middle: 2px;
    --common-box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.280);
    --common-color-white: #fff;
    --common-color-purple: #4b2139;
    --common-color-gray: #f2f2f2;
    --common-color-shadow: rgba(0, 0, 0, 0.280);
    --common-color-none-active-hover: rgb(112, 78, 112, 0.3);
    --common-font-size-too-light: 12px;
    --common-font-size-light: 14px;
    --common-font-size-middle: 16px;
    --common-font-size-heavy: 18px;
}

* {
    font-size: var(--common-font-size-middle);
}

header {
    background-color: var(--common-color-white);
    border-bottom: solid 8px var(--common-color-purple);
    height: 68px;
}

nav {
    position: absolute;
    top: 68px;
    right: 0;
    left: 0;    
    z-index: 1000;    
    background-color: var(--common-color-gray);
}

aside {
    background-color: var(--common-color-gray);
    box-shadow: var(--common-box-shadow);
    width: 16%;
    min-height: 100vh;
    position: sticky;
    top: 0;
}

main {
    width: 84%;
}

h1 {
    font-weight: bold;
    color: var(--common-color-purple);
}

.bodyOverlapCenter {
    top: 0; left: 0; right: 0; bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bodyOverlapTop {
    top: 0; left: 0; right: 0; bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.wrapper {
    width: 100%;
    display: flex;
}

@media (min-width: 600px) and (max-width: 899px) {
    aside {
        width: 24%;
    }

    main {
        width: 76%;
    }
}

@media screen and (max-width: 599px) {
    aside {
        display: none;
    }

    main {
        width: 100%;
    }
}
