.content {
    width: 40%;
    margin: 16vh auto 0;
}

.subjectAndClose {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
}

.subject {
    font-size: 24px;
}

.closeButton {
    display: flex; 
    align-items: center; 
    justify-content: center;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    transition: background-color 0.2s;
}

.closeButton:hover {
    background-color: var(--common-color-gray);
}

.block {
    background-color: var(--common-color-gray);
    padding: 16px;
    border-radius: 6px;
    box-shadow: var(--common-box-shadow);
}

.item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e1e1e1;
    position: relative; 
}

.item:last-child {
    border-bottom: none;
}

.label {
    font-weight: bold;
    color: var(--common-color-purple);
    width: 160px;
    flex-shrink: 0;
}

.katakana {
    font-size: 10px;
    display: block;
    margin-bottom: 4px;
}

.value {
    font-size: var(--common-font-size-heavy);
    white-space: normal;
    overflow-wrap: break-word;
    flex: 1;
    min-width: 0;
}

@media (min-width: 600px) and (max-width: 899px) {
    .content {
        margin: 8vh auto 0;
        width: 80%;
    }
}
 
@media screen and (max-width: 599px) {
    .content {
        margin: 4vh auto 0;
        width: 100%;
    }

    .subjectAndClose {
       width: 90%;
       margin: 0 auto 12px;
    }
}
 
@media (orientation: landscape) and (max-width: 1200px) {
    .content {
        margin: 10vh auto 0;
        width: 80%;
    }
}