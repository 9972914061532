.serviceWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.serviceWrapper button {
    font-size: var(--common-font-size-light);
    padding: 2px 4px;
    transition: background-color 0.3s;
    background-color: transparent; 
    cursor: pointer;

    &:hover {
        font-weight: bold;
    }

    &.active {
        box-shadow: 0 var(--common-border-width-middle) 0 var(--common-color-purple);
        font-weight: bold;
        transform: translateY(-2px);

        &:hover {
            background-color: var(--common-color-gray);
        }
    }
}

.uploadIcon {
    cursor: pointer;
}