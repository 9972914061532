.lettersContent {
    width: 72%;
    margin: 14vh auto 0;

    ul {
        list-style-type: disc;
        margin-bottom: 36px;
    }

    li {
        cursor: pointer;
        margin-bottom: 12px;

        span {
            color: blue;
        }
    }
}

.block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
        color: var(--common-color-white);
        font-weight: bold;
        border-radius: 6px;
        background-color: #694358;
        width: max-content;
        margin-left: 8px;
        padding: 6px 12px;
        transition: background-color 0.3s; 

        &:hover {
            background-color: var(--common-color-purple);
        }
    }
}

.subject {
    color: var(--common-color-purple);
    font-weight: bold;
    margin-bottom: 2px;
}

.value {
    font-size: var(--common-font-size-heavy);
}

.closeButton {
    margin-bottom: 24px;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    transition: background-color 0.2s;
}

.closeButton:hover {
    background-color: var(--common-color-gray);
}

@media (min-width: 600px) and (max-width: 899px) {
    .lettersContent {
        margin: 6vh auto 0;
        width: 80%;
    }
}
 
@media screen and (max-width: 599px) {
    .lettersContent {
        margin: 4vh auto 0;
        width: 90%;
    }
}
 
@media (orientation: landscape) and (max-width: 1200px) {
    .lettersContent {
        margin: 8vh auto 0;
        width: 80%;
    }
}