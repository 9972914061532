.content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    background-color: var(--common-color-gray);
    padding: 1.5rem 2rem;
    border-radius: 10px;
    box-shadow: var(--common-box-shadow);
    
    div {
        margin-bottom: 1rem;
    }

    label {
        font-weight: bold;
        color: var(--common-color-purple);
    }

    input:not([type="file"]) {
        width: 100%;
        border-radius: 4px;
        box-shadow: inset 0 0 0 var(--common-border-width-light) var(--common-color-shadow);
        line-height: 36px;
        padding: 0 12px;
        transition: all 0.2s; 

        &:focus {
            border-color: var(--common-color-purple);
            box-shadow: inset 0 0 0 var(--common-border-width-light) var(--common-color-purple);
        }
    }

    input[type="file"] {
        width: 100%;
        margin-top: 1rem;
    }

    ol {
        list-style-type: decimal;
    }

    button {
        width: max-content;
        background-color: #694358;
        color: var(--common-color-white);
        font-weight: bold;
        border-radius: 4px;
        padding: 8px 16px;
        transition: background-color 0.3s; 
        cursor: pointer;

        &:hover {
            background-color: var(--common-color-purple);
        }
    }

    .uploadBlock {
        display: flex;
        gap: 4px;
        align-items: baseline;
        margin-bottom: 0;
    }

    .progress {
        color: green;
        font-weight: bold;
    }
}