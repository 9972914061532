.content {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.block {
    margin-left: 12px;

    li {
        margin-top: 12px;

        span {
            display: inline-block;
            cursor: pointer;

            &:hover {
                box-shadow: 0 var(--common-border-width-middle) 0 var(--common-color-purple);
                transform: translateY(-1px);
            }
        }

        .icon:hover + span {
            box-shadow: 0 var(--common-border-width-middle) 0 var(--common-color-purple);
            transform: translateY(-1px);
        }
    }
}

.icon {
    margin-right: 8px;
    cursor: pointer;
}


