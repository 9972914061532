.error {
    color: red;
    font-weight: bold;
}

.reset {
    color: green;
    font-weight: bold;
}

.loginForm {
    background-color: var(--common-color-white);
    padding: 30px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 28%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    input {
        border-radius: 6px;
        box-shadow: inset 0 0 0 var(--common-border-width-light) var(--common-color-shadow);
        line-height: 40px;
        padding: 0 12px;
        transition: all 0.2s; 

        &:focus {
            border-color: var(--common-color-purple);
            box-shadow: inset 0 0 0 var(--common-border-width-light) var(--common-color-purple);
        }
    }

    button {
        color: var(--common-color-white);
        font-size: var(--common-font-size-heavy);
        font-weight: bold;
        border-radius: 6px;
        background-color: #694358;
        width: max-content;
        padding: 8px 16px;
        transition: background-color 0.3s; 

        &:hover {
            background-color: var(--common-color-purple);
        }
    }
}

.block {
    display: flex;
    align-items: baseline;
    gap: 20px;

    span {
        display: inline-block;
        font-size: var(--common-font-size-light);
        cursor: pointer;

        &:hover {
            box-shadow: 0 var(--common-border-width-middle) 0 var(--common-color-purple);
            transform: translateY(-1px);
        }
    }
}

@media screen and (max-width: 599px) {
    .loginForm {
        width: 90%;
    }
}