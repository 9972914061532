.buttonsWrapper {
    display: flex;
    flex-direction: column;
    width: max-content;
    margin: 24px auto 20px;
    gap: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 80%;
    margin: 0 auto;
    box-shadow: var(--common-box-shadow);
    background-color: var(--common-color-gray);
    padding: 20px 28px 24px;
}

@media screen and (max-width: 599px) {
    .content {
        width: 90%;
        padding: 14px 16px;
        gap: 12px;
    }
}