.typeWrapper {
    display: flex;
    align-items: center;
    gap: 20px;
}

.typeWrapper button {
    font-size: var(--common-font-size-light);
    border: solid var(--common-border-width-light) var(--common-color-shadow);
    border-right: 0;
    padding: 3px 6px;
    transition: background-color 0.3s;
    background-color: transparent; 
    cursor: pointer;

    &:last-child {
        border-right: solid var(--common-border-width-light) var(--common-color-shadow);
    }

    &:hover {
        background-color: var(--common-color-none-active-hover);
    }

    &.active {
        background-color: var(--common-color-purple);
        color: var(--common-color-white);

        &:hover {
            background-color: var(--common-color-purple);
        }
    }
}