.navButton {
    display: none;
}

.navButton:hover {
    background-color: var(--common-color-gray);
}

.content {
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.block {
    margin: 0 24px;

    h1 {
        font-size: var(--common-font-size-heavy);
    }

    ul {
        margin-top: 8px;
        background-color: var(--common-color-white);
    }

    li {
        font-size: var(--common-font-size-heavy);
        padding: 12px;
        border: solid var(--common-border-width-light) var(--common-color-shadow);
        border-bottom: 0;

        &:last-child {
            border-bottom: solid var(--common-border-width-light) var(--common-color-shadow);
        }

        &:hover {
            background-color: var(--common-color-shadow);
        }
    }
}

.icon {
    margin-right: 8px;
}

@media screen and (max-width: 599px) {
    .navButton {
        display: flex; 
        align-items: center; 
        justify-content: center;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        transition: background-color 0.2s;
    }
}