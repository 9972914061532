.content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 28px;
}
  
.block {
    position: relative;
    background-color: var(--common-color-white);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 12px 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s;

    &:hover {
        transform: translateY(-5px);
    }
}

.newLabel {
    position: absolute;
    top: 0; 
    right: 0;
    background-color: red;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: var(--common-font-size-too-light);
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.fileName {
    margin-top: 6px;
    font-size: var(--common-font-size-too-light);
    text-align: center;
    overflow-wrap: break-word;
    word-break: break-all;
    cursor: pointer;
}

.element {
    width: 100%;
    margin-top: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center; 
}

.date {
    font-size: var(--common-font-size-too-light);
    color: #888;
}

.icon {
    border: none;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        background-color: var(--common-color-gray);
    }
}

.highOrColorOverlay {
    position: absolute;
    bottom: 68px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    font-size: var(--common-font-size-light);
}

.highOrColorOverlay button {
    background-color: #694358;
    color: var(--common-color-white);
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: var(--common-color-purple);
    }
}


@media screen and (max-width: 599px) {
    .content {
        gap: 14px;
    }
}

